import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MedicalRecordsService } from '../../../api-client/services';
import { MedicalRecordDto } from './../../../api-client/models/medical-record-dto';

@Component({
  selector: 'app-get-medical-records',
  templateUrl: './get-medical-records.component.html',
  styleUrls: ['./get-medical-records.component.scss'],
  imports: [RouterLink, CommonModule],
  standalone: true,
})
export class GetMedicalRecordsComponent implements OnInit {
  medicalRecords: MedicalRecordDto[] = [];

  constructor(private medicalRecordsService: MedicalRecordsService) {}

  ngOnInit(): void {
    this.getMedicalRecords();
  }

  getMedicalRecords() {
    this.medicalRecordsService.getMedicalRecords$Json$Response().subscribe({
      next: (response) => {
        this.medicalRecords = response.body;
        console.log('Data fetched successfully', response.body);
      },
      error: (error) => {
        console.error('Error fetching data', error);
      },
    });
  }
}
