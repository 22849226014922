import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DrugService } from '../../../api-client/services';
import { DrugVm } from '../../../view-models/drug.vm';

@Component({
  selector: 'app-view-drugs',
  templateUrl: './view-drugs.component.html',
  styleUrls: ['./view-drugs.component.css'],
  imports: [RouterLink, CommonModule],
  standalone: true,
})
export class ViewDrugsComponent implements OnInit {
  drugs: DrugVm[] = [];

  constructor(private drugService: DrugService) {}

  ngOnInit() {
    this.getDrugs();
  }

  getDrugs() {
    this.drugService.getDrugs$Json$Response().subscribe({
      next: (response) => {
        for (let drug of response.body) {
          let drugVm = new DrugVm(drug);
          this.drugs.push(drugVm);
        }
        console.log('Data fetched successfully', response.body);
      },
      error: (error) => {
        console.error('Error fetching data', error);
      },
    });
  }
}
