export class ImageHelpers {
  public static convertToBase64(byteData: Uint8Array): string {
    let binary = '';
    const bytes = new Uint8Array(byteData);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return 'data:image/jpeg;base64,' + window.btoa(binary);
  }

  public static convertImgDataToBlob(imgData: string): Blob {
    const byteCharacters = atob(imgData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    return blob;
  }
}
