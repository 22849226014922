import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiClientConfig } from './api-client.config';
import { ApiModule } from './api-client/api.module';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    ...ApiClientConfig,
    provideRouter(routes),
    provideHttpClient(),
    importProvidersFrom(ApiModule.forRoot({ rootUrl: environment.apiUrl })),
  ],
};
