import { Component, HostBinding, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  standalone: true,
  imports: [RouterModule],
})
export class SideNavComponent implements OnInit {
  @HostBinding('class') classes = 'sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary';

  constructor() { }

  ngOnInit() {
  }

}
