<div>
  <h2>Medical Records</h2>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Patient</th>
        <th scope="col">Temp</th>
        <th scope="col">Weight</th>
        <th scope="col">Drug Records</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let r of medicalRecords">
        <td>{{r.createdDate}}</td>
        <td>{{r.patient?.name}} - {{r.patient?.type}}</td>
        <td>{{r.temperature != null ? r.temperature : 'None'}}</td>
        <td>{{r.weight != null ? r.weight : 'None'}}</td>
        <td>
          <ul>
            <li *ngFor="let dr of r.drugRecords">
              {{dr.quantity}} {{dr.unit?.name }} of {{ dr.drug?.name }} applied to {{ dr.administrationSite?.name }} by
              {{
              dr.administeredBy?.lastName }}, {{ dr.administeredBy?.prefix !== null
              ? dr.administeredBy?.prefix + ' ' + dr.administeredBy?.firstName : dr.administeredBy?.firstName}}
            </li>
          </ul>
        </td>
        <td>
          <a class="btn btn-link" [routerLink]="['/medical-records', r.id]">View</a>
          <a class="btn btn-link" [routerLink]="['/medical-records/edit', r.id]">Edit</a>
        </td>
      </tr>
    </tbody>
  </table>
  <a [routerLink]="['/medical-records/add']">Create New Record</a>
</div>