/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteQuoteRequest } from '../fn/quote-requests/delete-quote-request';
import { DeleteQuoteRequest$Params } from '../fn/quote-requests/delete-quote-request';
import { getQuoteRequest$Json } from '../fn/quote-requests/get-quote-request-json';
import { GetQuoteRequest$Json$Params } from '../fn/quote-requests/get-quote-request-json';
import { getQuoteRequest$Plain } from '../fn/quote-requests/get-quote-request-plain';
import { GetQuoteRequest$Plain$Params } from '../fn/quote-requests/get-quote-request-plain';
import { getQuoteRequests$Json } from '../fn/quote-requests/get-quote-requests-json';
import { GetQuoteRequests$Json$Params } from '../fn/quote-requests/get-quote-requests-json';
import { getQuoteRequests$Plain } from '../fn/quote-requests/get-quote-requests-plain';
import { GetQuoteRequests$Plain$Params } from '../fn/quote-requests/get-quote-requests-plain';
import { postQuoteRequest$Json } from '../fn/quote-requests/post-quote-request-json';
import { PostQuoteRequest$Json$Params } from '../fn/quote-requests/post-quote-request-json';
import { postQuoteRequest$Plain } from '../fn/quote-requests/post-quote-request-plain';
import { PostQuoteRequest$Plain$Params } from '../fn/quote-requests/post-quote-request-plain';
import { putQuoteRequest } from '../fn/quote-requests/put-quote-request';
import { PutQuoteRequest$Params } from '../fn/quote-requests/put-quote-request';
import { QuoteRequest } from '../models/quote-request';
import { QuoteRequestDto } from '../models/quote-request-dto';

@Injectable({ providedIn: 'root' })
export class QuoteRequestsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getQuoteRequests()` */
  static readonly GetQuoteRequestsPath = '/api/QuoteRequests';

  /**
   * Gets the quote requests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuoteRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequests$Plain$Response(params?: GetQuoteRequests$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<QuoteRequestDto>>> {
    return getQuoteRequests$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the quote requests.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuoteRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequests$Plain(params?: GetQuoteRequests$Plain$Params, context?: HttpContext): Observable<Array<QuoteRequestDto>> {
    return this.getQuoteRequests$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<QuoteRequestDto>>): Array<QuoteRequestDto> => r.body)
    );
  }

  /**
   * Gets the quote requests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuoteRequests$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequests$Json$Response(params?: GetQuoteRequests$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<QuoteRequestDto>>> {
    return getQuoteRequests$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the quote requests.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuoteRequests$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequests$Json(params?: GetQuoteRequests$Json$Params, context?: HttpContext): Observable<Array<QuoteRequestDto>> {
    return this.getQuoteRequests$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<QuoteRequestDto>>): Array<QuoteRequestDto> => r.body)
    );
  }

  /** Path part for operation `postQuoteRequest()` */
  static readonly PostQuoteRequestPath = '/api/QuoteRequests';

  /**
   * Posts the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postQuoteRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postQuoteRequest$Plain$Response(params?: PostQuoteRequest$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return postQuoteRequest$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postQuoteRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postQuoteRequest$Plain(params?: PostQuoteRequest$Plain$Params, context?: HttpContext): Observable<number> {
    return this.postQuoteRequest$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Posts the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postQuoteRequest$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postQuoteRequest$Json$Response(params?: PostQuoteRequest$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return postQuoteRequest$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postQuoteRequest$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postQuoteRequest$Json(params?: PostQuoteRequest$Json$Params, context?: HttpContext): Observable<number> {
    return this.postQuoteRequest$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getQuoteRequest()` */
  static readonly GetQuoteRequestPath = '/api/QuoteRequests/{id}';

  /**
   * Gets the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuoteRequest$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequest$Plain$Response(params: GetQuoteRequest$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<QuoteRequest>> {
    return getQuoteRequest$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuoteRequest$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequest$Plain(params: GetQuoteRequest$Plain$Params, context?: HttpContext): Observable<QuoteRequest> {
    return this.getQuoteRequest$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuoteRequest>): QuoteRequest => r.body)
    );
  }

  /**
   * Gets the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuoteRequest$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequest$Json$Response(params: GetQuoteRequest$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<QuoteRequest>> {
    return getQuoteRequest$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuoteRequest$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteRequest$Json(params: GetQuoteRequest$Json$Params, context?: HttpContext): Observable<QuoteRequest> {
    return this.getQuoteRequest$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuoteRequest>): QuoteRequest => r.body)
    );
  }

  /** Path part for operation `putQuoteRequest()` */
  static readonly PutQuoteRequestPath = '/api/QuoteRequests/{id}';

  /**
   * Puts the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putQuoteRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putQuoteRequest$Response(params: PutQuoteRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putQuoteRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Puts the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putQuoteRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putQuoteRequest(params: PutQuoteRequest$Params, context?: HttpContext): Observable<void> {
    return this.putQuoteRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteQuoteRequest()` */
  static readonly DeleteQuoteRequestPath = '/api/QuoteRequests/{id}';

  /**
   * Deletes the quote request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteQuoteRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuoteRequest$Response(params: DeleteQuoteRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteQuoteRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the quote request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteQuoteRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuoteRequest(params: DeleteQuoteRequest$Params, context?: HttpContext): Observable<void> {
    return this.deleteQuoteRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
