<h2>Medical Record Details</h2>
<div class="loading-cover d-flex justify-content-center align-items-center" *ngIf="isMedicalRecordLoading">
  <i class="fa-solid fa-circle-notch fa-spin"></i>
</div>
<form [formGroup]="medicalRecordForm" (ngSubmit)="onSubmit()" *ngIf="!isMedicalRecordLoading">
  <div class="row">
    <div class="col-12 col-sm-4">
      <div class="row">
        <div class="col mb-3">
          <label for="patient" class="form-label">Patient</label>
          <select id="patient" formControlName="patient" class="form-select">
            <option *ngFor="let patient of patients" [value]="patient">{{ patient.name }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col mb-3">
          <label for="temperature" class="form-label">Temperature</label>
          <input id="temperature" type="number" formControlName="temperature" class="form-control">
        </div>
        <div class="col mb-3">
          <label for="weight" class="form-label">Weight</label>
          <input id="weight" type="number" formControlName="weight" class="form-control">
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-8 mb-3">
      <label for="note" class="form-label">Note</label>
      <textarea id="note" formControlName="note" class="form-control"></textarea>
    </div>

    <div class="col-12 mb-3">
      <label for="cameraCapture" class="form-label">Capture Image</label>
      <input class="form-control" type="file" id="cameraCapture" (change)="onCameraCapture($event)" accept="image/*"
        capture="camera">
    </div>

    <div class="col-12 mb-3">
      <label for="formFile" class="form-label">Upload Images</label>
      <input class="form-control" type="file" id="formFile" (change)="onFilesSelected($event)" multiple>
      <div class="form-text" *ngIf="imageValidationMessages.length > 0" class="alert alert-danger">
        <ul>
          <li *ngFor="let message of imageValidationMessages">{{ message }}</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <ng-container *ngFor="let image of selectedMedicalRecordImages; let i = index">
          <div class="thumb-wrap">
            <img [src]="image.imageData" alt="Selected Image" class="img-thumbnail rounded float-start" />
            <button type="button" class="btn-remove" title="Remove" (click)="removeImage(image.id!)"><i
                class="fa-solid fa-xmark"></i></button>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

  <div class="row" formArrayName="drugRecords">
    <h4>Drug Records</h4>
    <div class="col mb-3">
      <div *ngFor="let drugRecord of drugRecords.controls; let i = index" [formGroupName]="i" class="card shadow mb-3">
        <div class="card-body pe-5">
          <button type="button" class="btn btn-sm btn-link text-danger card-btn" (click)="removeDrugRecord(i)"><i
              class="fa-solid fa-trash-can"></i></button>
          <div class="row">

            <div class="col-6 col-sm-3 col-lg-1 mb-3">
              <label [for]="'drug' + i" class="form-label">Drug</label>
              <select [id]="'drug' + i" formControlName="drug" class="form-select">
                <option *ngFor="let drug of drugs" [ngValue]="drug">{{ drug.name }}</option>
              </select>
            </div>

            <div class="col-6 col-sm-3 col-lg-1 mb-3">
              <label for="quantity" class="form-label">Quantity</label>
              <input id="quantity" type="number" formControlName="quantity" class="form-control">
            </div>

            <div class="col-6 col-sm-3 col-lg-1 mb-3">
              <label for="unit" class="form-label">Unit</label>
              <select id="unit" formControlName="unit" class="form-select">
                <option *ngFor="let unit of drugUnits" [ngValue]="unit">{{ unit.name }}</option>
              </select>
            </div>
          </div>
          <div class="row">

            <div class="col-12 col-sm-4 mb-3">
              <label for="administrationMethod" class="form-label">Administration Method</label>
              <select id="administrationMethod" formControlName="administrationMethod" class="form-select">
                <option *ngFor="let method of administrationMethods" [ngValue]="method">{{ method.name }}</option>
              </select>
            </div>

            <div class="col-12 col-sm-4 mb-3">
              <label for="administrationSite" class="form-label">Administration Site</label>
              <select id="administrationSite" formControlName="administrationSite" class="form-select">
                <option *ngFor="let site of administrationSites" [ngValue]="site">{{ site.name }}</option>
              </select>
            </div>

            <div class="col-12 col-sm-4 mb-3">
              <label for="administeredBy" class="form-label">Administered By</label>
              <select id="administeredBy" formControlName="administeredBy" class="form-select">
                <option *ngFor="let person of people" [ngValue]="person">{{ person.lastName }}, {{ person.prefix !==
                  null
                  ? person.prefix + ' ' + person.firstName : person.firstName}}</option>
              </select>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <label for="note" class="form-label">Note</label>
              <textarea id="note" formControlName="note" class="form-control"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-sm btn-link" (click)="addDrugRecord()"><i class="fa-solid fa-plus"></i>
        Add Drug
        Record</button>
    </div>


  </div>
  <div class="row">
    <div class="col d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" [disabled]="medicalRecordForm.invalid">Save</button>
    </div>
  </div>


</form>