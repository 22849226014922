/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteVenue } from '../fn/venues/delete-venue';
import { DeleteVenue$Params } from '../fn/venues/delete-venue';
import { getVenue$Json } from '../fn/venues/get-venue-json';
import { GetVenue$Json$Params } from '../fn/venues/get-venue-json';
import { getVenue$Plain } from '../fn/venues/get-venue-plain';
import { GetVenue$Plain$Params } from '../fn/venues/get-venue-plain';
import { getVenues$Json } from '../fn/venues/get-venues-json';
import { GetVenues$Json$Params } from '../fn/venues/get-venues-json';
import { getVenues$Plain } from '../fn/venues/get-venues-plain';
import { GetVenues$Plain$Params } from '../fn/venues/get-venues-plain';
import { postVenue$Json } from '../fn/venues/post-venue-json';
import { PostVenue$Json$Params } from '../fn/venues/post-venue-json';
import { postVenue$Plain } from '../fn/venues/post-venue-plain';
import { PostVenue$Plain$Params } from '../fn/venues/post-venue-plain';
import { putVenue } from '../fn/venues/put-venue';
import { PutVenue$Params } from '../fn/venues/put-venue';
import { Venue } from '../models/venue';

@Injectable({ providedIn: 'root' })
export class VenuesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVenues()` */
  static readonly GetVenuesPath = '/api/Venues';

  /**
   * Gets the venues.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenues$Plain$Response(params?: GetVenues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Venue>>> {
    return getVenues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the venues.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVenues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenues$Plain(params?: GetVenues$Plain$Params, context?: HttpContext): Observable<Array<Venue>> {
    return this.getVenues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Venue>>): Array<Venue> => r.body)
    );
  }

  /**
   * Gets the venues.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenues$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenues$Json$Response(params?: GetVenues$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Venue>>> {
    return getVenues$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the venues.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVenues$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenues$Json(params?: GetVenues$Json$Params, context?: HttpContext): Observable<Array<Venue>> {
    return this.getVenues$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Venue>>): Array<Venue> => r.body)
    );
  }

  /** Path part for operation `postVenue()` */
  static readonly PostVenuePath = '/api/Venues';

  /**
   * Posts the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVenue$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVenue$Plain$Response(params?: PostVenue$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Venue>> {
    return postVenue$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postVenue$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVenue$Plain(params?: PostVenue$Plain$Params, context?: HttpContext): Observable<Venue> {
    return this.postVenue$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Venue>): Venue => r.body)
    );
  }

  /**
   * Posts the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVenue$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVenue$Json$Response(params?: PostVenue$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Venue>> {
    return postVenue$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postVenue$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVenue$Json(params?: PostVenue$Json$Params, context?: HttpContext): Observable<Venue> {
    return this.postVenue$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Venue>): Venue => r.body)
    );
  }

  /** Path part for operation `getVenue()` */
  static readonly GetVenuePath = '/api/Venues/{id}';

  /**
   * Gets the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenue$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue$Plain$Response(params: GetVenue$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Venue>> {
    return getVenue$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVenue$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue$Plain(params: GetVenue$Plain$Params, context?: HttpContext): Observable<Venue> {
    return this.getVenue$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Venue>): Venue => r.body)
    );
  }

  /**
   * Gets the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenue$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue$Json$Response(params: GetVenue$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Venue>> {
    return getVenue$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVenue$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue$Json(params: GetVenue$Json$Params, context?: HttpContext): Observable<Venue> {
    return this.getVenue$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Venue>): Venue => r.body)
    );
  }

  /** Path part for operation `putVenue()` */
  static readonly PutVenuePath = '/api/Venues/{id}';

  /**
   * Puts the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVenue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVenue$Response(params: PutVenue$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putVenue(this.http, this.rootUrl, params, context);
  }

  /**
   * Puts the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putVenue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVenue(params: PutVenue$Params, context?: HttpContext): Observable<void> {
    return this.putVenue$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteVenue()` */
  static readonly DeleteVenuePath = '/api/Venues/{id}';

  /**
   * Deletes the venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVenue$Response(params: DeleteVenue$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteVenue(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the venue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVenue(params: DeleteVenue$Params, context?: HttpContext): Observable<void> {
    return this.deleteVenue$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
