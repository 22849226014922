<!-- <app-header></app-header> -->

<div class="container-fluid">
  <div class="row">
    <ng-container *ngIf="showSidebar">
      <app-side-nav></app-side-nav>
    </ng-container>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-4">
      <router-outlet></router-outlet>
      <app-toast></app-toast>
    </main>
  </div>
</div>