/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteImage } from '../fn/medical-records/delete-image';
import { DeleteImage$Params } from '../fn/medical-records/delete-image';
import { deleteMedicalRecord } from '../fn/medical-records/delete-medical-record';
import { DeleteMedicalRecord$Params } from '../fn/medical-records/delete-medical-record';
import { getImages$Json } from '../fn/medical-records/get-images-json';
import { GetImages$Json$Params } from '../fn/medical-records/get-images-json';
import { getImages$Plain } from '../fn/medical-records/get-images-plain';
import { GetImages$Plain$Params } from '../fn/medical-records/get-images-plain';
import { getMedicalRecord$Json } from '../fn/medical-records/get-medical-record-json';
import { GetMedicalRecord$Json$Params } from '../fn/medical-records/get-medical-record-json';
import { getMedicalRecord$Plain } from '../fn/medical-records/get-medical-record-plain';
import { GetMedicalRecord$Plain$Params } from '../fn/medical-records/get-medical-record-plain';
import { getMedicalRecords$Json } from '../fn/medical-records/get-medical-records-json';
import { GetMedicalRecords$Json$Params } from '../fn/medical-records/get-medical-records-json';
import { getMedicalRecords$Plain } from '../fn/medical-records/get-medical-records-plain';
import { GetMedicalRecords$Plain$Params } from '../fn/medical-records/get-medical-records-plain';
import { MedicalRecordDto } from '../models/medical-record-dto';
import { MedicalRecordImageDto } from '../models/medical-record-image-dto';
import { postMedicalRecord } from '../fn/medical-records/post-medical-record';
import { PostMedicalRecord$Params } from '../fn/medical-records/post-medical-record';
import { putMedicalRecord } from '../fn/medical-records/put-medical-record';
import { PutMedicalRecord$Params } from '../fn/medical-records/put-medical-record';
import { uploadImages } from '../fn/medical-records/upload-images';
import { UploadImages$Params } from '../fn/medical-records/upload-images';

@Injectable({ providedIn: 'root' })
export class MedicalRecordsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMedicalRecords()` */
  static readonly GetMedicalRecordsPath = '/api/MedicalRecords';

  /**
   * Retrieves all medical records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalRecords$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecords$Plain$Response(params?: GetMedicalRecords$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MedicalRecordDto>>> {
    return getMedicalRecords$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves all medical records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalRecords$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecords$Plain(params?: GetMedicalRecords$Plain$Params, context?: HttpContext): Observable<Array<MedicalRecordDto>> {
    return this.getMedicalRecords$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MedicalRecordDto>>): Array<MedicalRecordDto> => r.body)
    );
  }

  /**
   * Retrieves all medical records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalRecords$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecords$Json$Response(params?: GetMedicalRecords$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MedicalRecordDto>>> {
    return getMedicalRecords$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves all medical records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalRecords$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecords$Json(params?: GetMedicalRecords$Json$Params, context?: HttpContext): Observable<Array<MedicalRecordDto>> {
    return this.getMedicalRecords$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MedicalRecordDto>>): Array<MedicalRecordDto> => r.body)
    );
  }

  /** Path part for operation `postMedicalRecord()` */
  static readonly PostMedicalRecordPath = '/api/MedicalRecords';

  /**
   * Posts the medical record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMedicalRecord()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMedicalRecord$Response(params?: PostMedicalRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postMedicalRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the medical record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postMedicalRecord$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMedicalRecord(params?: PostMedicalRecord$Params, context?: HttpContext): Observable<void> {
    return this.postMedicalRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMedicalRecord()` */
  static readonly GetMedicalRecordPath = '/api/MedicalRecords/{id}';

  /**
   * Retrieves a specific medical record by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalRecord$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecord$Plain$Response(params: GetMedicalRecord$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalRecordDto>> {
    return getMedicalRecord$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a specific medical record by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalRecord$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecord$Plain(params: GetMedicalRecord$Plain$Params, context?: HttpContext): Observable<MedicalRecordDto> {
    return this.getMedicalRecord$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MedicalRecordDto>): MedicalRecordDto => r.body)
    );
  }

  /**
   * Retrieves a specific medical record by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalRecord$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecord$Json$Response(params: GetMedicalRecord$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalRecordDto>> {
    return getMedicalRecord$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a specific medical record by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalRecord$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalRecord$Json(params: GetMedicalRecord$Json$Params, context?: HttpContext): Observable<MedicalRecordDto> {
    return this.getMedicalRecord$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MedicalRecordDto>): MedicalRecordDto => r.body)
    );
  }

  /** Path part for operation `putMedicalRecord()` */
  static readonly PutMedicalRecordPath = '/api/MedicalRecords/{id}';

  /**
   * Updates a specific medical record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMedicalRecord()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putMedicalRecord$Response(params: PutMedicalRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putMedicalRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a specific medical record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putMedicalRecord$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putMedicalRecord(params: PutMedicalRecord$Params, context?: HttpContext): Observable<void> {
    return this.putMedicalRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteMedicalRecord()` */
  static readonly DeleteMedicalRecordPath = '/api/MedicalRecords/{id}';

  /**
   * Deletes a specific medical record by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMedicalRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMedicalRecord$Response(params: DeleteMedicalRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMedicalRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes a specific medical record by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMedicalRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMedicalRecord(params: DeleteMedicalRecord$Params, context?: HttpContext): Observable<void> {
    return this.deleteMedicalRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getImages()` */
  static readonly GetImagesPath = '/api/MedicalRecords/{id}/images';

  /**
   * Gets the images.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Plain$Response(params: GetImages$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MedicalRecordImageDto>>> {
    return getImages$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the images.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Plain(params: GetImages$Plain$Params, context?: HttpContext): Observable<Array<MedicalRecordImageDto>> {
    return this.getImages$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MedicalRecordImageDto>>): Array<MedicalRecordImageDto> => r.body)
    );
  }

  /**
   * Gets the images.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Json$Response(params: GetImages$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MedicalRecordImageDto>>> {
    return getImages$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the images.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Json(params: GetImages$Json$Params, context?: HttpContext): Observable<Array<MedicalRecordImageDto>> {
    return this.getImages$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MedicalRecordImageDto>>): Array<MedicalRecordImageDto> => r.body)
    );
  }

  /** Path part for operation `deleteImage()` */
  static readonly DeleteImagePath = '/api/MedicalRecords/{id}/images';

  /**
   * Deletes the image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteImage$Response(params: DeleteImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteImage(params: DeleteImage$Params, context?: HttpContext): Observable<void> {
    return this.deleteImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadImages()` */
  static readonly UploadImagesPath = '/api/MedicalRecords/{id}/upload';

  /**
   * Uploads the images.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadImages()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImages$Response(params: UploadImages$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadImages(this.http, this.rootUrl, params, context);
  }

  /**
   * Uploads the images.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadImages$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImages(params: UploadImages$Params, context?: HttpContext): Observable<void> {
    return this.uploadImages$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
