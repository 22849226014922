import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MedicalRecordDto } from '../../../api-client/models';
import { MedicalRecordsService } from '../../../api-client/services';

@Component({
  selector: 'app-get-medical-record-detail',
  templateUrl: './get-medical-record-detail.component.html',
  styleUrls: ['./get-medical-record-detail.component.scss'],
  imports: [CommonModule, RouterLink],
  standalone: true,
})
export class GetMedicalRecordDetailComponent implements OnInit {
  medicalRecord: MedicalRecordDto | null = null;

  constructor(
    private medicalRecordsService: MedicalRecordsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getMedicalRecord(parseInt(id));
    }
  }

  getMedicalRecord(id: number) {
    this.medicalRecordsService
      .getMedicalRecord$Json$Response({ id: id })
      .subscribe({
        next: (response) => {
          this.medicalRecord = response.body;
          console.log('Data fetched successfully', response.body);
        },
        error: (error) => {
          console.error('Error fetching data', error);
        },
      });
  }

  deleteRecord(): void {
    if (this.medicalRecord) {
      this.medicalRecordsService
        .deleteMedicalRecord$Response({ id: this.medicalRecord.id! })
        .subscribe({
          next: (response) => {
            this.router.navigate(['/medical-records']);
          },
          error: (error) => {
            console.error('Error fetching data', error);
          },
        });
    }
  }
}
