<div>
  <h2>Drugs</h2>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Expiration Date</th>
        <th scope="col">Days Until Expiration</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let r of drugs">
        <td>{{r.name}}</td>
        <td>{{r.expirationDate}}</td>
        <td>{{r.daysUntilExpiration}}</td>
        <td>
          <a class="btn btn-link" [routerLink]="['/drugs', r.id]">View</a>
          <a class="btn btn-link" [routerLink]="['/drugs/edit', r.id]">Edit</a>
        </td>
      </tr>
    </tbody>
  </table>
  <a [routerLink]="['/drugs/add']">Create New Drug</a>
</div>