/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createClient } from '../fn/external-apis/create-client';
import { CreateClient$Params } from '../fn/external-apis/create-client';
import { getFreshbooksAccountId } from '../fn/external-apis/get-freshbooks-account-id';
import { GetFreshbooksAccountId$Params } from '../fn/external-apis/get-freshbooks-account-id';
import { index_1 } from '../fn/external-apis/index-1';
import { Index_1$Params } from '../fn/external-apis/index-1';
import { listFreshbooksClients } from '../fn/external-apis/list-freshbooks-clients';
import { ListFreshbooksClients$Params } from '../fn/external-apis/list-freshbooks-clients';
import { putGoogleCalendarAuthCode } from '../fn/external-apis/put-google-calendar-auth-code';
import { PutGoogleCalendarAuthCode$Params } from '../fn/external-apis/put-google-calendar-auth-code';

@Injectable({ providedIn: 'root' })
export class ExternalApisService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `index_1()` */
  static readonly Index_1Path = '/externalapis/freshbooksredirect';

  /**
   * Indices the.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `index_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  index_1$Response(params?: Index_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return index_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Indices the.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `index_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  index_1(params?: Index_1$Params, context?: HttpContext): Observable<void> {
    return this.index_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getFreshbooksAccountId()` */
  static readonly GetFreshbooksAccountIdPath = '/api/ExternalApis/getFreshbooksAccountId';

  /**
   * Retrieves the Freshbooks account ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFreshbooksAccountId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFreshbooksAccountId$Response(params?: GetFreshbooksAccountId$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getFreshbooksAccountId(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the Freshbooks account ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFreshbooksAccountId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFreshbooksAccountId(params?: GetFreshbooksAccountId$Params, context?: HttpContext): Observable<string> {
    return this.getFreshbooksAccountId$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `listFreshbooksClients()` */
  static readonly ListFreshbooksClientsPath = '/api/ExternalApis/list-clients';

  /**
   * Lists Freshbooks clients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFreshbooksClients()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFreshbooksClients$Response(params?: ListFreshbooksClients$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return listFreshbooksClients(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists Freshbooks clients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listFreshbooksClients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFreshbooksClients(params?: ListFreshbooksClients$Params, context?: HttpContext): Observable<void> {
    return this.listFreshbooksClients$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createClient()` */
  static readonly CreateClientPath = '/api/ExternalApis/postClient';

  /**
   * Creates a new Freshbooks client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createClient$Response(params?: CreateClient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createClient(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new Freshbooks client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createClient(params?: CreateClient$Params, context?: HttpContext): Observable<void> {
    return this.createClient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putGoogleCalendarAuthCode()` */
  static readonly PutGoogleCalendarAuthCodePath = '/api/ExternalApis/googlecalendarauthcode/{code}';

  /**
   * Puts the google calendar auth code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putGoogleCalendarAuthCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  putGoogleCalendarAuthCode$Response(params: PutGoogleCalendarAuthCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putGoogleCalendarAuthCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Puts the google calendar auth code.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putGoogleCalendarAuthCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putGoogleCalendarAuthCode(params: PutGoogleCalendarAuthCode$Params, context?: HttpContext): Observable<void> {
    return this.putGoogleCalendarAuthCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
