/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdministrationMethodsService } from './services/administration-methods.service';
import { AdministrationSitesService } from './services/administration-sites.service';
import { DrugService } from './services/drug.service';
import { DrugUnitsService } from './services/drug-units.service';
import { EventsService } from './services/events.service';
import { ExternalApisService } from './services/external-apis.service';
import { MedicalRecordsService } from './services/medical-records.service';
import { OrganizationsService } from './services/organizations.service';
import { PatientsService } from './services/patients.service';
import { PeopleService } from './services/people.service';
import { QuoteRequestsService } from './services/quote-requests.service';
import { VenuesService } from './services/venues.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdministrationMethodsService,
    AdministrationSitesService,
    DrugService,
    DrugUnitsService,
    EventsService,
    ExternalApisService,
    MedicalRecordsService,
    OrganizationsService,
    PatientsService,
    PeopleService,
    QuoteRequestsService,
    VenuesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
