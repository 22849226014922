<div class="row">
  <div class="col">
    <h2>Medical Record Details</h2>
    <ul>
      <li>Created: {{medicalRecord?.createdDate}}</li>
      <li>Patient Name: {{medicalRecord?.patient?.name}}</li>
      <li>Patient Type: {{medicalRecord?.patient?.type}}</li>
      <li>Temp: {{medicalRecord?.temperature}}</li>
      <li>Weight: {{medicalRecord?.weight}}</li>
      <li>Drug Records:
        <ul *ngFor="let dr of medicalRecord?.drugRecords">
          <li>Drug: {{ dr.drug?.name }}</li>
          <li>Unit: {{ dr.unit?.name}}</li>
          <li>Quantity: {{ dr.quantity}}</li>
          <li>Administration Method: {{ dr.administrationMethod?.name }}</li>
          <li>Administration Site: {{ dr.administrationSite?.name }}</li>
          <li>Administered By: {{ dr.administeredBy?.lastName }}, {{ dr.administeredBy?.prefix !== null
            ? dr.administeredBy?.prefix + ' ' + dr.administeredBy?.firstName : dr.administeredBy?.firstName }}</li>
          <li>Note: {{ dr.note }}</li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="medicalRecord">
  <button (click)="deleteRecord()">Delete</button>
  <a [routerLink]="['/medical-records/edit', medicalRecord.id]">Edit</a>
</div>