import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AddMedicalRecordComponent } from './pages/medical/add-medical-record/add-medical-record.component';
import { GetMedicalRecordDetailComponent } from './pages/medical/get-medical-record-detail/get-medical-record-detail.component';
import { GetMedicalRecordsComponent } from './pages/medical/get-medical-records/get-medical-records.component';
import { ViewDrugsComponent } from './pages/medical/view-drugs/view-drugs.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SplashComponent } from './pages/splash/splash.component';

export const routes: Routes = [
  {
    path: '',
    component: SplashComponent,
    title: '',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    title: 'Dashboard',
  },
  {
    path: 'medical-records',
    component: GetMedicalRecordsComponent,
    pathMatch: 'full',
    title: 'Medical Records',
  },
  {
    path: 'medical-records/add',
    component: AddMedicalRecordComponent,
    pathMatch: 'full',
    title: 'Add Medical Record',
  },
  {
    path: 'medical-records/edit/:id',
    component: AddMedicalRecordComponent,
    title: 'Edit Medical Record',
  },
  {
    path: 'medical-records/:id',
    component: GetMedicalRecordDetailComponent,
    title: 'Medical Record Detail',
  },
  {
    path: 'drugs',
    component: ViewDrugsComponent,
    pathMatch: 'full',
    title: 'Drugs',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Page Not Found',
  },
];
