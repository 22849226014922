import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastMessage, ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class ToastComponent implements OnInit {
  toasts: ToastMessage[] = [];

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.toastState.subscribe((toast: ToastMessage) => {
      this.toasts.push(toast);
      setTimeout(() => this.removeToast(toast), 3000); // Remove toast after 3 seconds
    });
  }

  removeToast(toast: ToastMessage) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
