/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deletePerson } from '../fn/people/delete-person';
import { DeletePerson$Params } from '../fn/people/delete-person';
import { getPeople$Json } from '../fn/people/get-people-json';
import { GetPeople$Json$Params } from '../fn/people/get-people-json';
import { getPeople$Plain } from '../fn/people/get-people-plain';
import { GetPeople$Plain$Params } from '../fn/people/get-people-plain';
import { getPeopleWithBusinessRoles$Json } from '../fn/people/get-people-with-business-roles-json';
import { GetPeopleWithBusinessRoles$Json$Params } from '../fn/people/get-people-with-business-roles-json';
import { getPeopleWithBusinessRoles$Plain } from '../fn/people/get-people-with-business-roles-plain';
import { GetPeopleWithBusinessRoles$Plain$Params } from '../fn/people/get-people-with-business-roles-plain';
import { getPerson$Json } from '../fn/people/get-person-json';
import { GetPerson$Json$Params } from '../fn/people/get-person-json';
import { getPerson$Plain } from '../fn/people/get-person-plain';
import { GetPerson$Plain$Params } from '../fn/people/get-person-plain';
import { Person } from '../models/person';
import { PersonDto } from '../models/person-dto';
import { postPerson$Json } from '../fn/people/post-person-json';
import { PostPerson$Json$Params } from '../fn/people/post-person-json';
import { postPerson$Plain } from '../fn/people/post-person-plain';
import { PostPerson$Plain$Params } from '../fn/people/post-person-plain';
import { putPerson } from '../fn/people/put-person';
import { PutPerson$Params } from '../fn/people/put-person';

@Injectable({ providedIn: 'root' })
export class PeopleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPeople()` */
  static readonly GetPeoplePath = '/api/People';

  /**
   * Gets the people.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeople$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeople$Plain$Response(params?: GetPeople$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonDto>>> {
    return getPeople$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the people.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeople$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeople$Plain(params?: GetPeople$Plain$Params, context?: HttpContext): Observable<Array<PersonDto>> {
    return this.getPeople$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonDto>>): Array<PersonDto> => r.body)
    );
  }

  /**
   * Gets the people.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeople$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeople$Json$Response(params?: GetPeople$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonDto>>> {
    return getPeople$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the people.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeople$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeople$Json(params?: GetPeople$Json$Params, context?: HttpContext): Observable<Array<PersonDto>> {
    return this.getPeople$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonDto>>): Array<PersonDto> => r.body)
    );
  }

  /** Path part for operation `postPerson()` */
  static readonly PostPersonPath = '/api/People';

  /**
   * Posts the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPerson$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPerson$Plain$Response(params?: PostPerson$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Person>> {
    return postPerson$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPerson$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPerson$Plain(params?: PostPerson$Plain$Params, context?: HttpContext): Observable<Person> {
    return this.postPerson$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Person>): Person => r.body)
    );
  }

  /**
   * Posts the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPerson$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPerson$Json$Response(params?: PostPerson$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Person>> {
    return postPerson$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPerson$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPerson$Json(params?: PostPerson$Json$Params, context?: HttpContext): Observable<Person> {
    return this.postPerson$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Person>): Person => r.body)
    );
  }

  /** Path part for operation `getPeopleWithBusinessRoles()` */
  static readonly GetPeopleWithBusinessRolesPath = '/api/People/getPeopleWithBusinessRoles';

  /**
   * Gets the people with roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeopleWithBusinessRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeopleWithBusinessRoles$Plain$Response(params?: GetPeopleWithBusinessRoles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonDto>>> {
    return getPeopleWithBusinessRoles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the people with roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeopleWithBusinessRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeopleWithBusinessRoles$Plain(params?: GetPeopleWithBusinessRoles$Plain$Params, context?: HttpContext): Observable<Array<PersonDto>> {
    return this.getPeopleWithBusinessRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonDto>>): Array<PersonDto> => r.body)
    );
  }

  /**
   * Gets the people with roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeopleWithBusinessRoles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeopleWithBusinessRoles$Json$Response(params?: GetPeopleWithBusinessRoles$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonDto>>> {
    return getPeopleWithBusinessRoles$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the people with roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeopleWithBusinessRoles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeopleWithBusinessRoles$Json(params?: GetPeopleWithBusinessRoles$Json$Params, context?: HttpContext): Observable<Array<PersonDto>> {
    return this.getPeopleWithBusinessRoles$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonDto>>): Array<PersonDto> => r.body)
    );
  }

  /** Path part for operation `getPerson()` */
  static readonly GetPersonPath = '/api/People/{id}';

  /**
   * Gets the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerson$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson$Plain$Response(params: GetPerson$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Person>> {
    return getPerson$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPerson$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson$Plain(params: GetPerson$Plain$Params, context?: HttpContext): Observable<Person> {
    return this.getPerson$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Person>): Person => r.body)
    );
  }

  /**
   * Gets the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerson$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson$Json$Response(params: GetPerson$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Person>> {
    return getPerson$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPerson$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson$Json(params: GetPerson$Json$Params, context?: HttpContext): Observable<Person> {
    return this.getPerson$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Person>): Person => r.body)
    );
  }

  /** Path part for operation `putPerson()` */
  static readonly PutPersonPath = '/api/People/{id}';

  /**
   * Puts the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPerson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putPerson$Response(params: PutPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Puts the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putPerson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putPerson(params: PutPerson$Params, context?: HttpContext): Observable<void> {
    return this.putPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deletePerson()` */
  static readonly DeletePersonPath = '/api/People/{id}';

  /**
   * Deletes the person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePerson$Response(params: DeletePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePerson(params: DeletePerson$Params, context?: HttpContext): Observable<void> {
    return this.deletePerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
