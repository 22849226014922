/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteOrganization } from '../fn/organizations/delete-organization';
import { DeleteOrganization$Params } from '../fn/organizations/delete-organization';
import { getOrganization$Json } from '../fn/organizations/get-organization-json';
import { GetOrganization$Json$Params } from '../fn/organizations/get-organization-json';
import { getOrganization$Plain } from '../fn/organizations/get-organization-plain';
import { GetOrganization$Plain$Params } from '../fn/organizations/get-organization-plain';
import { getOrganizations$Json } from '../fn/organizations/get-organizations-json';
import { GetOrganizations$Json$Params } from '../fn/organizations/get-organizations-json';
import { getOrganizations$Plain } from '../fn/organizations/get-organizations-plain';
import { GetOrganizations$Plain$Params } from '../fn/organizations/get-organizations-plain';
import { Organization } from '../models/organization';
import { postOrganization$Json } from '../fn/organizations/post-organization-json';
import { PostOrganization$Json$Params } from '../fn/organizations/post-organization-json';
import { postOrganization$Plain } from '../fn/organizations/post-organization-plain';
import { PostOrganization$Plain$Params } from '../fn/organizations/post-organization-plain';
import { putOrganization } from '../fn/organizations/put-organization';
import { PutOrganization$Params } from '../fn/organizations/put-organization';

@Injectable({ providedIn: 'root' })
export class OrganizationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganizations()` */
  static readonly GetOrganizationsPath = '/api/Organizations';

  /**
   * Gets the organizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Plain$Response(params?: GetOrganizations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Organization>>> {
    return getOrganizations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the organizations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Plain(params?: GetOrganizations$Plain$Params, context?: HttpContext): Observable<Array<Organization>> {
    return this.getOrganizations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>): Array<Organization> => r.body)
    );
  }

  /**
   * Gets the organizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Json$Response(params?: GetOrganizations$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Organization>>> {
    return getOrganizations$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the organizations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Json(params?: GetOrganizations$Json$Params, context?: HttpContext): Observable<Array<Organization>> {
    return this.getOrganizations$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>): Array<Organization> => r.body)
    );
  }

  /** Path part for operation `postOrganization()` */
  static readonly PostOrganizationPath = '/api/Organizations';

  /**
   * Posts the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganization$Plain$Response(params?: PostOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return postOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganization$Plain(params?: PostOrganization$Plain$Params, context?: HttpContext): Observable<Organization> {
    return this.postOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

  /**
   * Posts the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrganization$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganization$Json$Response(params?: PostOrganization$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return postOrganization$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postOrganization$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganization$Json(params?: PostOrganization$Json$Params, context?: HttpContext): Observable<Organization> {
    return this.postOrganization$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

  /** Path part for operation `getOrganization()` */
  static readonly GetOrganizationPath = '/api/Organizations/{id}';

  /**
   * Gets the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Plain$Response(params: GetOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return getOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Plain(params: GetOrganization$Plain$Params, context?: HttpContext): Observable<Organization> {
    return this.getOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

  /**
   * Gets the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Json$Response(params: GetOrganization$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return getOrganization$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Json(params: GetOrganization$Json$Params, context?: HttpContext): Observable<Organization> {
    return this.getOrganization$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

  /** Path part for operation `putOrganization()` */
  static readonly PutOrganizationPath = '/api/Organizations/{id}';

  /**
   * Puts the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putOrganization$Response(params: PutOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Puts the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putOrganization(params: PutOrganization$Params, context?: HttpContext): Observable<void> {
    return this.putOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOrganization()` */
  static readonly DeleteOrganizationPath = '/api/Organizations/{id}';

  /**
   * Deletes the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization$Response(params: DeleteOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization(params: DeleteOrganization$Params, context?: HttpContext): Observable<void> {
    return this.deleteOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
