import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastMessage {
  type: 'success' | 'danger' | 'warning' | 'info';
  title: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<ToastMessage>();
  toastState = this.toastSubject.asObservable();

  showToast(type: 'success' | 'danger' | 'warning' | 'info', message: string) {
    let title = '';
    switch (type) {
      case 'success':
        title = 'Success';
        break;
      case 'danger':
        title = 'Error';
        break;
      case 'warning':
        title = 'Warning';
        break;
      case 'info':
        title = 'Info';
        break;
    }
    this.toastSubject.next({ type, title, message });
  }
}
