import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './theme/header/header.component';
import { SideNavComponent } from './theme/side-nav/side-nav.component';
import { ToastComponent } from './theme/toast/toast.component';

import { NgIf } from '@angular/common';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    SideNavComponent,
    DashboardComponent,
    ToastComponent,
    NgIf,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Zoobility';
  showSidebar: boolean = true;

  firebaseConfig = {
    apiKey: 'AIzaSyBLqG8-zDWkVLWWAdfN0V_N8GKQV6SfhL0',
    authDomain: 'zoobility-4fae4.firebaseapp.com',
    projectId: 'zoobility-4fae4',
    storageBucket: 'zoobility-4fae4.appspot.com',
    messagingSenderId: '117535171423',
    appId: '1:117535171423:web:02210f3f5db6ddc070ccea',
    measurementId: 'G-NMPW0C5WHG',
  };

  app = initializeApp(this.firebaseConfig);
  analytics = getAnalytics(this.app);

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the SplashComponent route
        this.showSidebar = event.url !== '/';
      }
    });
  }
}
