import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, Observable } from 'rxjs';
import {
  AdministrationMethod,
  AdministrationSite,
  Drug,
  DrugRecordDto,
  DrugUnit,
  MedicalRecordImageDto,
  Patient,
  Person,
  PersonDto,
} from '../../../api-client/models';
import {
  AdministrationMethodsService,
  AdministrationSitesService,
  DrugService,
  DrugUnitsService,
  MedicalRecordsService,
  PatientsService,
  PeopleService,
} from '../../../api-client/services';
import { ImageHelpers } from '../../../helpers/image-helpers';
import { ToastService } from '../../../services/toast.service';
import { PutMedicalRecord$Params } from './../../../api-client/fn/medical-records/put-medical-record';
import { MedicalRecordDto } from './../../../api-client/models/medical-record-dto';

@Component({
  selector: 'app-add-medical-record',
  templateUrl: './add-medical-record.component.html',
  styleUrls: ['./add-medical-record.component.scss'],
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
})
export class AddMedicalRecordComponent implements OnInit {
  medicalRecordDto: MedicalRecordDto | null = null;
  isMedicalRecordLoading: boolean = true;
  medicalRecordForm: FormGroup = this.fb.group({
    drugRecords: this.fb.array([]),
    note: [null],
    patient: [null, Validators.required],
    temperature: [null],
    weight: [null],
  });

  // Form options.
  patients: Patient[] = [];
  people: PersonDto[] = [];
  administrationMethods: AdministrationMethod[] = [];
  administrationSites: AdministrationSite[] = [];
  drugUnits: DrugUnit[] = [];
  drugs: Drug[] = [];

  isEditMode: boolean = false;
  medicalRecordId: number | null = null;
  selectedFiles: File[] = [];
  originalMedicalRecordImages: MedicalRecordImageDto[] = [];
  selectedMedicalRecordImages: MedicalRecordImageDto[] = [];
  medicalRecordImagesToRemove: MedicalRecordImageDto[] = [];
  imageValidationMessages: string[] = [];
  readonly MAX_FILE_SIZE = 2 * 1024 * 1024; // 5 MB
  readonly ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

  constructor(
    private fb: FormBuilder,
    private medicalRecordsService: MedicalRecordsService,
    private drugService: DrugService,
    private administrationMethodsService: AdministrationMethodsService,
    private administrationSitesService: AdministrationSitesService,
    private drugUnitsService: DrugUnitsService,
    private patientsService: PatientsService,
    private peopleService: PeopleService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.loadFormOptions();

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.isEditMode = true;
        this.medicalRecordId = +id;

        this.loadMedicalRecord(this.medicalRecordId);
      }
    });
  }

  get drugRecords(): FormArray {
    return this.medicalRecordForm.get('drugRecords') as FormArray;
  }

  loadFormOptions() {
    // Create an array to hold the observables
    const formOptionObservables: { [key: string]: Observable<any> } = {
      drugs: this.drugService
        .getDrugs$Json$Response()
        .pipe(map((response) => response.body)),
      administrationMethods: this.administrationMethodsService
        .getAdministrationMethods$Json$Response()
        .pipe(map((response) => response.body)),
      administrationSites: this.administrationSitesService
        .getAdministrationSites$Json$Response()
        .pipe(map((response) => response.body)),
      drugUnits: this.drugUnitsService
        .getDrugUnits$Json$Response()
        .pipe(map((response) => response.body)),
      patients: this.patientsService
        .getPatients$Json$Response()
        .pipe(map((response) => response.body)),
      people: this.peopleService
        .getPeopleWithBusinessRoles$Json$Response()
        .pipe(map((response) => response.body)),
    };

    // Combine all observables using forkJoin
    forkJoin({ ...formOptionObservables, ...formOptionObservables }).subscribe({
      next: (results) => {
        this.drugs = results['drugs'];
        this.administrationMethods = results['administrationMethods'];
        this.administrationSites = results['administrationSites'];
        this.drugUnits = results['drugUnits'];
        this.patients = results['patients'];
        this.people = results['people'];
        this.isMedicalRecordLoading = false;
      },
      error: (err) => {
        this.toastService.showToast(
          'danger',
          'Error fetching data: ' + err.message
        );
      },
    });
  }

  addDrugRecord(): void {
    console.log('ppl', this.people);
    this.drugRecords.push(
      this.fb.group({
        administrationMethod: [this.administrationMethods[0]],
        administeredBy: [this.people[0]],
        administrationSite: [this.administrationSites[0]],
        drug: [this.drugs[0]],
        note: [null],
        quantity: [null, Validators.required],
        unit: [this.drugUnits[0]],
      })
    );
  }

  removeDrugRecord(index: number): void {
    this.drugRecords.removeAt(index);
  }

  loadMedicalRecord(id: number): void {
    const loadDataObservables: { [key: string]: Observable<any> } = {
      medRecord: this.medicalRecordsService
        .getMedicalRecord$Json$Response({ id })
        .pipe(map((response) => response.body)),
      images: this.medicalRecordsService
        .getImages$Json$Response({ id })
        .pipe(map((response) => response.body)),
    };
    forkJoin({ ...loadDataObservables, ...loadDataObservables }).subscribe({
      next: (results) => {
        this.medicalRecordDto = results['medRecord'];
        let images = results['images'];
        console.log('dto', this.medicalRecordDto);

        this.populateMedicalRecordForm(this.medicalRecordDto!);
        this.populateImageForm(images);

        this.isMedicalRecordLoading = false;
      },
      error: (err) => {
        this.isMedicalRecordLoading = false;
        this.toastService.showToast(
          'danger',
          'Error loading Medical Record: ' + err.message
        );
      },
    });
  }

  populateMedicalRecordForm(dto: MedicalRecordDto): void {
    this.medicalRecordForm.patchValue(dto);

    // Populate drugRecords FormArray
    if (dto.drugRecords) {
      console.log('drug recs', dto.drugRecords);
      dto.drugRecords.forEach((drugRecord: DrugRecordDto) => {
        this.drugRecords.push(
          this.fb.group({
            administrationMethod: [
              this.administrationMethods.find(
                (x) => x.id == drugRecord.administrationMethod!.id
              ) ?? drugRecord.administrationMethod,
            ],
            administrationSite: [
              this.administrationSites.find(
                (x) => x.id == drugRecord.administrationSite!.id
              ) ?? drugRecord.administrationSite,
            ],
            administeredBy: [
              this.people.find((x) => x.id == drugRecord.administeredBy!.id) ??
                drugRecord.administeredBy,
            ],
            drug:
              this.drugs.find((x) => x.id == drugRecord.drug!.id) ??
              drugRecord.drug,
            id: [drugRecord.id],
            note: [drugRecord.note],
            quantity: [drugRecord.quantity],
            unit: [
              this.drugUnits.find((x) => x.id == drugRecord.unit!.id) ??
                drugRecord.unit,
            ],
          })
        );
      });
    }
  }
  populateImageForm(dtos: MedicalRecordImageDto[]): void {
    for (let img of dtos) {
      const blob = ImageHelpers.convertImgDataToBlob(img.imageData!);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let imgDto = {
          id: img.id!,
          mimeType: img.mimeType!,
          medicalRecordId: this.medicalRecordId!,
          imageData: reader.result as string,
        } as MedicalRecordImageDto;

        this.originalMedicalRecordImages.push(imgDto);
        this.selectedMedicalRecordImages.push(imgDto);
      };
    }
  }

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = [];
      this.imageValidationMessages = [];

      for (const file of Array.from(input.files)) {
        if (!this.ALLOWED_FILE_TYPES.includes(file.type)) {
          this.imageValidationMessages.push(
            `File type not allowed: ${file.name}.  Only jpeg, png, or gif allowed.`
          );
          continue;
        }

        if (file.size > this.MAX_FILE_SIZE) {
          this.imageValidationMessages.push(
            `File too large: ${file.name}. Max size: 2mb.`
          );
          continue;
        }

        this.selectedFiles.push(file);
        console.log('this.selectedfiles', this.selectedFiles);

        const reader = new FileReader();
        reader.onload = (e: any) => {
          console.log('e', e);
          this.selectedMedicalRecordImages.push({
            mimeType: file.type,
            medicalRecordId: this.medicalRecordId!,
            imageData: e.target.result,
          });
          console.log(
            'selectedMedicalRecordImages',
            this.selectedMedicalRecordImages
          );
        };
        reader.readAsDataURL(file);
      }
    }
  }

  uploadImages(): void {
    if (this.selectedFiles.length === 0) {
      // No new images were uploaded, but some may have been removed.
      if (this.medicalRecordImagesToRemove.length > 0) {
        for (let i of this.medicalRecordImagesToRemove) {
          this.deleteImage(i.id!);
        }
      }
    } else {
      // New images were uploaded.
      const formData = new FormData();
      this.selectedFiles.forEach((file) => {
        formData.append('images', file); // Append each file to the FormData object
      });
      this.medicalRecordsService
        .uploadImages$Response({
          id: this.medicalRecordId!,
          body: { files: this.selectedFiles },
        })
        .subscribe({
          next: (response) => {
            console.log('Images uploaded successfully', response.body);
            this.toastService.showToast(
              'success',
              'Images uploaded successfully'
            );
            this.redirectPage();
          },
          error: (err) => {
            this.toastService.showToast(
              'danger',
              'Error uploading images: ' + err.message
            );
          },
        });
    }
  }

  deleteImage(id: number): void {
    this.medicalRecordsService
      .deleteImage$Response({ id: id.toString() })
      .subscribe({
        next: (response) => {
          this.toastService.showToast('success', 'Image deleted successfully');
          this.selectedMedicalRecordImages =
            this.selectedMedicalRecordImages.filter((x) => x.id !== id);
        },
        error: (err) => {
          this.toastService.showToast(
            'danger',
            'Error deleting image: ' + err.message
          );
        },
      });
  }

  removeImage(id: number) {
    var imgIndex = this.selectedMedicalRecordImages.indexOf(
      this.selectedMedicalRecordImages.find((x) => x.id === id)!
    );
    this.medicalRecordImagesToRemove.push(
      this.selectedMedicalRecordImages[imgIndex]
    );
    this.selectedMedicalRecordImages.splice(imgIndex, 1);
  }

  onSubmit(): void {
    if (this.medicalRecordForm.valid) {
      const medicalRecord: MedicalRecordDto = this.medicalRecordForm.value;
      const drugRecords = medicalRecord.drugRecords;
      // Create the Person object since People returns a list of personDtos.
      // Transform the drugRecords to ensure administeredBy is correctly formatted
      if (drugRecords) {
        if (drugRecords.length > 0) {
          let newDrugRecords: DrugRecordDto[] = [];
          for (let drugRecord of drugRecords) {
            let p: Person = {
              id: drugRecord.administeredBy!.id,
            };
            newDrugRecords.push({
              ...drugRecord,
              administeredBy: p,
            });
          }
        }
      }

      if (this.isEditMode && this.medicalRecordId !== null) {
        medicalRecord.id = this.medicalRecordId;
        this.updateMedicalRecord(medicalRecord);
      } else {
        this.createMedicalRecord(medicalRecord);
      }
    }
  }

  createMedicalRecord(medicalRecord: MedicalRecordDto): void {
    // This is a POST for a new record.
    this.medicalRecordsService
      .postMedicalRecord$Response({ body: medicalRecord })
      .subscribe({
        next: (response) => {
          console.log('Medical record added successfully', response.body);
          this.toastService.showToast(
            'success',
            'Medical record added successfully'
          );
          this.uploadImages();
        },
        error: (err) => {
          this.toastService.showToast(
            'danger',
            'Error posting data: ' + err.message
          );
        },
      });
  }

  updateMedicalRecord(medicalRecord: MedicalRecordDto): void {
    // This is a PUT for an existing record.
    if (
      this.selectedMedicalRecordImages.length > 0 ||
      this.medicalRecordImagesToRemove.length > 0
    ) {
      this.uploadImages();
    }

    const params = {
      id: this.medicalRecordId,
      body: medicalRecord,
    } as PutMedicalRecord$Params;
    this.medicalRecordsService.putMedicalRecord$Response(params).subscribe({
      next: (response) => {
        this.toastService.showToast(
          'success',
          'Medical record updated successfully'
        );
        this.redirectPage();
      },
      error: (err) => {
        this.toastService.showToast(
          'danger',
          'Error posting data: ' + err.message
        );
      },
    });
  }

  onCameraCapture(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imageData = e.target.result;
        this.selectedMedicalRecordImages.push({
          id: parseInt(this.generateUniqueId()),
          imageData: imageData,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  generateUniqueId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  redirectPage() {
    setTimeout(() => {
      this.router.navigate(['/medical-records']);
    }, 3000);
  }
}
