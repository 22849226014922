/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Drug } from '../models/drug';
import { getDrugs$Json } from '../fn/drug/get-drugs-json';
import { GetDrugs$Json$Params } from '../fn/drug/get-drugs-json';
import { getDrugs$Plain } from '../fn/drug/get-drugs-plain';
import { GetDrugs$Plain$Params } from '../fn/drug/get-drugs-plain';

@Injectable({ providedIn: 'root' })
export class DrugService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDrugs()` */
  static readonly GetDrugsPath = '/api/Drug';

  /**
   * Gets the drugs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDrugs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugs$Plain$Response(params?: GetDrugs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Drug>>> {
    return getDrugs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the drugs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDrugs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugs$Plain(params?: GetDrugs$Plain$Params, context?: HttpContext): Observable<Array<Drug>> {
    return this.getDrugs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Drug>>): Array<Drug> => r.body)
    );
  }

  /**
   * Gets the drugs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDrugs$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugs$Json$Response(params?: GetDrugs$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Drug>>> {
    return getDrugs$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the drugs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDrugs$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugs$Json(params?: GetDrugs$Json$Params, context?: HttpContext): Observable<Array<Drug>> {
    return this.getDrugs$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Drug>>): Array<Drug> => r.body)
    );
  }

}
