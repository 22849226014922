/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DrugUnit } from '../models/drug-unit';
import { getDrugUnits$Json } from '../fn/drug-units/get-drug-units-json';
import { GetDrugUnits$Json$Params } from '../fn/drug-units/get-drug-units-json';
import { getDrugUnits$Plain } from '../fn/drug-units/get-drug-units-plain';
import { GetDrugUnits$Plain$Params } from '../fn/drug-units/get-drug-units-plain';

@Injectable({ providedIn: 'root' })
export class DrugUnitsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDrugUnits()` */
  static readonly GetDrugUnitsPath = '/api/DrugUnits';

  /**
   * Gets the drug units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDrugUnits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugUnits$Plain$Response(params?: GetDrugUnits$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DrugUnit>>> {
    return getDrugUnits$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the drug units.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDrugUnits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugUnits$Plain(params?: GetDrugUnits$Plain$Params, context?: HttpContext): Observable<Array<DrugUnit>> {
    return this.getDrugUnits$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DrugUnit>>): Array<DrugUnit> => r.body)
    );
  }

  /**
   * Gets the drug units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDrugUnits$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugUnits$Json$Response(params?: GetDrugUnits$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DrugUnit>>> {
    return getDrugUnits$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the drug units.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDrugUnits$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrugUnits$Json(params?: GetDrugUnits$Json$Params, context?: HttpContext): Observable<Array<DrugUnit>> {
    return this.getDrugUnits$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DrugUnit>>): Array<DrugUnit> => r.body)
    );
  }

}
