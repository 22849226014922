/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addGoogleCalendarEvent } from '../fn/events/add-google-calendar-event';
import { AddGoogleCalendarEvent$Params } from '../fn/events/add-google-calendar-event';
import { callback } from '../fn/events/callback';
import { Callback$Params } from '../fn/events/callback';
import { deleteEvent } from '../fn/events/delete-event';
import { DeleteEvent$Params } from '../fn/events/delete-event';
import { Event } from '../models/event';
import { getAllGoogleCalendars } from '../fn/events/get-all-google-calendars';
import { GetAllGoogleCalendars$Params } from '../fn/events/get-all-google-calendars';
import { getEvent } from '../fn/events/get-event';
import { GetEvent$Params } from '../fn/events/get-event';
import { getEvents } from '../fn/events/get-events';
import { GetEvents$Params } from '../fn/events/get-events';
import { getGoogleCalendarEventsByDate } from '../fn/events/get-google-calendar-events-by-date';
import { getGoogleCalendarEventsByDate_1 } from '../fn/events/get-google-calendar-events-by-date-1';
import { GetGoogleCalendarEventsByDate_1$Params } from '../fn/events/get-google-calendar-events-by-date-1';
import { GetGoogleCalendarEventsByDate$Params } from '../fn/events/get-google-calendar-events-by-date';
import { googleAuth } from '../fn/events/google-auth';
import { GoogleAuth$Params } from '../fn/events/google-auth';
import { index } from '../fn/events/index';
import { Index$Params } from '../fn/events/index';
import { postEvent } from '../fn/events/post-event';
import { PostEvent$Params } from '../fn/events/post-event';
import { putEvent } from '../fn/events/put-event';
import { PutEvent$Params } from '../fn/events/put-event';

@Injectable({ providedIn: 'root' })
export class EventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `index()` */
  static readonly IndexPath = '/events/index';

  /**
   * Indices the.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `index()` instead.
   *
   * This method doesn't expect any request body.
   */
  index$Response(params?: Index$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return index(this.http, this.rootUrl, params, context);
  }

  /**
   * Indices the.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `index$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  index(params?: Index$Params, context?: HttpContext): Observable<void> {
    return this.index$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEvents()` */
  static readonly GetEventsPath = '/api/Events';

  /**
   * retrieve a list of all Events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents$Response(params?: GetEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * retrieve a list of all Events.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents(params?: GetEvents$Params, context?: HttpContext): Observable<void> {
    return this.getEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postEvent()` */
  static readonly PostEventPath = '/api/Events';

  /**
   * Posts the event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEvent$Response(params?: PostEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<Event>> {
    return postEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Posts the event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEvent(params?: PostEvent$Params, context?: HttpContext): Observable<Event> {
    return this.postEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Event>): Event => r.body)
    );
  }

  /** Path part for operation `getEvent()` */
  static readonly GetEventPath = '/api/Events/{id}';

  /**
   * retrieve an event by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvent$Response(params: GetEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * retrieve an event by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvent(params: GetEvent$Params, context?: HttpContext): Observable<void> {
    return this.getEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putEvent()` */
  static readonly PutEventPath = '/api/Events/{id}';

  /**
   * Update an existing event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEvent$Response(params: PutEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an existing event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEvent(params: PutEvent$Params, context?: HttpContext): Observable<void> {
    return this.putEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteEvent()` */
  static readonly DeleteEventPath = '/api/Events/{id}';

  /**
   * Deletes the event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent$Response(params: DeleteEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent(params: DeleteEvent$Params, context?: HttpContext): Observable<void> {
    return this.deleteEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `googleAuth()` */
  static readonly GoogleAuthPath = '/auth/google';

  /**
   * Googles the auth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleAuth$Response(params?: GoogleAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return googleAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * Googles the auth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleAuth(params?: GoogleAuth$Params, context?: HttpContext): Observable<void> {
    return this.googleAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `callback()` */
  static readonly CallbackPath = '/auth/callback';

  /**
   * Callbacks the.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callback()` instead.
   *
   * This method doesn't expect any request body.
   */
  callback$Response(params?: Callback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return callback(this.http, this.rootUrl, params, context);
  }

  /**
   * Callbacks the.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  callback(params?: Callback$Params, context?: HttpContext): Observable<void> {
    return this.callback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllGoogleCalendars()` */
  static readonly GetAllGoogleCalendarsPath = '/events/getAllGoogleCalendars';

  /**
   * gets the all google calendars.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGoogleCalendars()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGoogleCalendars$Response(params?: GetAllGoogleCalendars$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getAllGoogleCalendars(this.http, this.rootUrl, params, context);
  }

  /**
   * gets the all google calendars.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllGoogleCalendars$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGoogleCalendars(params?: GetAllGoogleCalendars$Params, context?: HttpContext): Observable<void> {
    return this.getAllGoogleCalendars$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getGoogleCalendarEventsByDate()` */
  static readonly GetGoogleCalendarEventsByDatePath = '/events/gCalendarEventsByDate/{calendarId}/{date}';

  /**
   * Gets the google calendar events by date.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoogleCalendarEventsByDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleCalendarEventsByDate$Response(params: GetGoogleCalendarEventsByDate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getGoogleCalendarEventsByDate(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the google calendar events by date.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGoogleCalendarEventsByDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleCalendarEventsByDate(params: GetGoogleCalendarEventsByDate$Params, context?: HttpContext): Observable<void> {
    return this.getGoogleCalendarEventsByDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getGoogleCalendarEventsByDate_1()` */
  static readonly GetGoogleCalendarEventsByDate_1Path = '/events/getDistance/{destination}';

  /**
   * Gets the google calendar events by date.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoogleCalendarEventsByDate_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleCalendarEventsByDate_1$Response(params: GetGoogleCalendarEventsByDate_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getGoogleCalendarEventsByDate_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the google calendar events by date.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGoogleCalendarEventsByDate_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleCalendarEventsByDate_1(params: GetGoogleCalendarEventsByDate_1$Params, context?: HttpContext): Observable<void> {
    return this.getGoogleCalendarEventsByDate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addGoogleCalendarEvent()` */
  static readonly AddGoogleCalendarEventPath = '/events/gcalendarevent';

  /**
   * Adds the google calendar event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGoogleCalendarEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGoogleCalendarEvent$Response(params?: AddGoogleCalendarEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addGoogleCalendarEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds the google calendar event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addGoogleCalendarEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGoogleCalendarEvent(params?: AddGoogleCalendarEvent$Params, context?: HttpContext): Observable<void> {
    return this.addGoogleCalendarEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
