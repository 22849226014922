<div class="toast-container position-fixed bottom-0 end-0 p-3" *ngIf="toasts.length > 0">
  <div class="toast fade show" *ngFor="let toast of toasts" [ngClass]="toast.type" role="alert" aria-live="assertive"
    aria-atomic="true">
    <div class="toast-header">
      <strong class="me-auto">{{toast.title}}</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      {{ toast.message }}
    </div>
  </div>
</div>