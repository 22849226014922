import moment from 'moment';
import { Drug } from './../api-client/models/drug';

export interface DrugVm extends Drug {
  daysUntilExpiration: number;
}
export class DrugVm implements DrugVm {
  constructor(drug: Drug) {
    Object.assign(this, drug);
    this.daysUntilExpiration = this.calculateTimeUntilExpiration(
      this.expirationDate!
    );
  }
  calculateTimeUntilExpiration(expirationDate: string): number {
    const expDateM = moment(expirationDate);
    const currentDate = moment();
    return currentDate.diff(expDateM, 'days') * -1;
  }
}
